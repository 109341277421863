import fetchPage from './fetchPage';
import searchProducts from './searchProducts';
import searchInfo from './searchInfo';
import getStaticPage from './getStaticPage';
import getFabricSamples from './getFabricSamples';
import getSearchProductsCount from './getSearchProductsCount';
import getPriceTagData from './getPriceTagData';
import getPreorderDate from './getPreorderDate';
import getSurveys from './getSurveys';
import saveSurvey from './saveSurvey';
import getSEOCategories from './getSEOCategories';

export default {
  searchProducts,
  fetchPage,
  searchInfo,
  getStaticPage,
  getFabricSamples,
  getSearchProductsCount,
  getPriceTagData,
  getPreorderDate,
  getSurveys,
  saveSurvey,
  getSEOCategories,
};
