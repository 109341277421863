import { memo } from 'react';
import { cn, PopoverInfo } from '@divlab/divanui';
import { IconInfoPopover } from '@divlab/divanui/icons';

import { useFeatureFlags } from '@Contexts/FeatureFlags';
import SubscriptionForm from '@Forms/SubscriptionForm';
import SocialList from '@Components/SocialList';
import SmartRender from '@Components/SmartRender';
import FooterNav from '@Layouts/LayoutMain/elems/Footer/common/FooterNav';
import Accordion from '@Layouts/LayoutMain/elems/Footer/common/Accordion';
import FooterInfoBlock from '@Layouts/LayoutMain/elems/Footer/common/FooterInfoBlock';
import useTranslation from '@Queries/useTranslation';
import DeveloperLink from '../DeveloperLink';
import FooterLogo from '../FooterLogo';
import PaymentSystems from '../PaymentSystems';
import FooterOfferDoc from '../FooterOfferDoc';
import FooterPublicOffers from '../FooterPublicOffers';
import styles from './FooterDesktop.module.css';

import type { FC } from 'react';
import type { FooterProps } from '../../FooterRus';

const FooterDesktop: FC<FooterProps> = (props) => {
  const { footer } = props;
  const featureFlags = useFeatureFlags();
  const { t } = useTranslation();

  return (
    <div className={styles.grid}>
      <div>
        {footer.contacts?.items.length > 0 && (
          <Accordion header={footer.contacts.title}>
            <FooterNav items={footer.contacts.items} />
          </Accordion>
        )}
      </div>

      <div>
        {footer.callSchedule?.items.length > 0 && (
          <div className={styles.wrapperMedium}>
            <FooterInfoBlock title={footer.callSchedule.title}>
              <FooterNav items={footer.callSchedule.items} />
            </FooterInfoBlock>
          </div>
        )}

        {footer.deliverySchedule?.items.length > 0 && (
          <div className={styles.wrapperBig}>
            <FooterInfoBlock title={footer.deliverySchedule.title}>
              <FooterNav items={footer.deliverySchedule.items} />
            </FooterInfoBlock>
          </div>
        )}

        {footer.subscribe && (
          <FooterInfoBlock title={footer.subscribe.title}>
            <div className={styles.footerSubscription}>
              <SubscriptionForm className={styles.subscriptionForm} source='footer' />
            </div>
          </FooterInfoBlock>
        )}

        {footer.press?.items.length > 0 && (
          <FooterInfoBlock title={footer.press.title}>
            <FooterNav items={footer.press.items} />
          </FooterInfoBlock>
        )}
      </div>

      <div>
        {footer.toCustomers?.items.length > 0 && (
          <Accordion header={footer.toCustomers.title}>
            <FooterNav items={footer.toCustomers?.items} />
          </Accordion>
        )}
      </div>

      <div>
        {footer.catalog?.items.length > 0 && (
          <div className={styles.wrapperMedium}>
            <Accordion defaultCollapsed header={footer.catalog.title}>
              <FooterNav items={footer.catalog.items} />
            </Accordion>
          </div>
        )}

        {footer.services?.items.length > 0 && (
          <FooterInfoBlock title={footer.services.title}>
            <FooterNav items={footer.services.items} />
          </FooterInfoBlock>
        )}
      </div>

      <div>
        {footer.reviews?.items.length > 0 && (
          <div className={styles.wrapperMedium}>
            <Accordion header={footer.reviews.title}>
              <FooterNav items={footer.reviews.items} />
            </Accordion>
          </div>
        )}

        {footer.awards?.items.length > 0 && (
          <div className={styles.wrapperBig}>
            <FooterInfoBlock title={footer.awards.title}>
              <FooterNav items={footer.awards.items} />
            </FooterInfoBlock>
          </div>
        )}

        {footer.payments?.items.length > 0 && (
          <FooterInfoBlock title={footer.payments.title}>
            <PaymentSystems items={footer.payments.items} />
          </FooterInfoBlock>
        )}
      </div>

      <FooterLogo />

      <div>
        {footer.socials?.items.length > 0 && (
          <SocialList className={styles.socials} items={footer.socials.items} />
        )}
      </div>

      <div>
        {footer.offerDocs?.map((item) => (
          <div key={item.text} className={styles.linkWrapper}>
            <FooterOfferDoc document={item} />
            <SmartRender countries={['UZB']}>
              <PopoverInfo
                trigger='hover'
                slotTrigger={<IconInfoPopover />}
                preferredPositions={['top-center']}
                className={styles.popoverInfo}
              >
                <div className={styles.popover}>{t('ui.footer.oferta-popover')}</div>
              </PopoverInfo>
            </SmartRender>
          </div>
        ))}
      </div>

      <div>
        <div className={styles.linkWrapper}>
          <FooterOfferDoc
            document={{
              action: 'StaticInfo',
              slug: 'privacy-policy',
              text: t('ui.footer-links.privacy-policy'),
            }}
            className={styles.footerLink}
          />
        </div>

        {footer.publicOffers?.items && (
          <div className={styles.linkWrapper}>
            <FooterPublicOffers publicOffers={footer.publicOffers} className={styles.footerLink} />
          </div>
        )}

        {footer?.paymentSecurity && (
          <div className={styles.linkWrapper}>
            <FooterOfferDoc
              document={{
                action: footer.paymentSecurity.action,
                slug: footer.paymentSecurity.slug,
                text: footer.paymentSecurity.title,
              }}
            />
          </div>
        )}
      </div>

      {featureFlags.needLinkToPaymentSecurity && (
        <div className={styles.paymentSecurityContainer}>
          <FooterOfferDoc
            document={{
              action: 'StaticInfo',
              slug: 'payment-security',
              text: t('ui.footer-links.payment-security'),
            }}
            className={cn(styles.footerLink, styles.paymentSecurity)}
          />
        </div>
      )}

      {featureFlags.needLinkToDeveloper && <DeveloperLink className={styles.madeIn} />}
    </div>
  );
};

export default memo(FooterDesktop);
