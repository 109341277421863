import * as Api from '@Api/index';
import { BackendError } from '@Common/errors';

import type { ApiResponse } from '@Types/Api';
import type { ApiSurvey } from '@Types/Survey';

async function getSurveys() {
  const url = '/backend/data/surveys';

  const response = await Api.queryProxi<ApiResponse<ApiSurvey[]>>(url);

  if (!response.ok) throw new BackendError(url, response);

  return response.data;
}

export default getSurveys;
