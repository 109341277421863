import * as Api from '@Api/index';

import type { FullSavedSurvey } from '@Types/Survey';

type SaveSurveyResult = {
  ok: boolean;
  error?: string;
};

async function saveSurvey(survey: FullSavedSurvey) {
  const url = `/marketing/form/v1/save-survey`;

  const result = await Api.queryProxi<SaveSurveyResult>(url, {
    method: 'POST',
    body: JSON.stringify(survey),
  });

  if (!result?.ok) throw new Error(result?.error || 'unknown error');

  return result;
}

export default saveSurvey;
